@import "../../scss/variables";
@import "../../scss/functions";
@import "../../scss/mixins/buttons";
@import "../../scss/mixins/direction";
@import '../../scss/mixins/preloader';
@import "../../scss/mixins/product-card";

.block-loader {
  position: relative;
  min-height: 320px;
}

.block-loader__spinner {
  @include preloader(80px);
}

.block-products__featured {
  display: flex;
  flex-direction: column;
  width: 36%;
  flex-shrink: 0;
}

.product-card-parent {
  margin-bottom: 20px;
}

.block-products__list {
  display: grid;
  grid-template-columns: repeat(4, calc((100% - 75px) / 4));
  width: 100%;
  gap: 25px;

  @media (max-width: 991px) {
    grid-template-columns: repeat(3, calc((100% - 50px) / 3));
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(2, calc((100% - 25px) / 2));
  }

  @media (max-width: 425px) {
    grid-template-columns: repeat(2, calc((100% - 10px) / 2));
    gap: 10px;
  }
}

.block-products__body {
  width: 100%;
  display: flex;
}

.block-products-carousel__column {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.block {
  margin: 80px 0 $block-margin-bottom-md;
}

.block-products-carousel {
  .slick-track {
    display: flex;
    align-items: stretch;
  }

  .slick-list {
    margin: 0 -5px;
    margin-bottom: -56px;

    &:hover {
      z-index: 2;
    }
  }

  .slick-slide {
    padding: 0 5px;
    height: auto;
    display: flex;

    &:focus,
    .correct-slick-active {
      outline: none;
    }

    & > div,
    & > div > div {
      width: 100%;
      display: flex !important;
      align-items: stretch;
    }
  }
}

.block-products-carousel__preloader {
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: $block-products-carousel-preloader-bg;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0s 0.3s;

  &:after {
    @include preloader(100px);
  }
}

.block-products-carousel--loading .block-products-carousel__preloader {
  transition-delay: 0s, 0s;
  opacity: 1;
  visibility: visible;
}

.block-products-carousel--has-items {
  .block-products-carousel__preloader {
    bottom: 56px;
  }
}

.block-products-carousel__slider {
  position: relative;
  min-height: 200px;
}

.block-products-carousel__cell {
  padding: 0 10px;
  //margin: 20px 0 60px;
}

.home-product-container {
  margin: 80px auto !important;
}

@media (min-width: 992px) {
  .block-products__featured + .block-products__list {
    @include direction {
      #{$margin-inline-start}: 5px;
    }
  }
  .block-products__list + .block-products__featured {
    @include direction {
      #{$margin-inline-start}: 5px * 2;
    }
  }
}

@media (max-width: 991px) {
  .block-products__body {
    display: block;
  }

  .block-products__featured {
    width: 100%;
  }

  .block-products__featured + .block-products__list {
    margin-top: 5px;
  }

  .block-products__list + .block-products__featured {
    margin-top: 5px * 2;
  }

  .home-product-container {
    max-width: 900px;
  }

  .block {
    margin-bottom: $block-margin-bottom-md;
  }
}

@media (max-width: 600px) {
  .block-products__body {
    margin-top: 0;
  }
}

@media (max-width: breakpoint(md-end)) {
  .block {
    margin-bottom: $block-margin-bottom-md;
  }
}

@media (max-width: 480px) {
  .block {
    margin: 27px 0;
  }
}

